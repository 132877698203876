<template>
  <div class="rich-text-area-section">
    <div class="position-relative w-100">
      <textarea
        ref="textarea"
        @focus="syncScroll"
        @scroll="syncScroll"
        class="rich-textarea"
        v-model="inputText"
        placeholder="Type here..."
      ></textarea>
      <div ref="lineContainer" v-html="formattedText" class="output"></div>
    </div>
    <div class="d-flex align-items-center justify-content-end w-100">
      <button
        @click="$refs['personalize-modal'].open()"
        class="rich-text-area-button"
      >
        + Add Personalization
      </button>
    </div>
    <Modal
      :id="'personalize-modal'"
      ref="personalize-modal"
      :isLine="true"
      :size="'lg'"
      :title="'Personalization'"
    >
      <div class="px-3">
        <div class="row">
          <div class="col-12 col-md-4">
            <div class="personalize-modal-title mb-2">Profile</div>
            <div class="d-flex flex-column" style="gap: 2px">
              <label
                :for="field.value + 'id'"
                class="d-flex m-0 pl-0 align-items-center personalize-item"
                :class="isChecked(field.value) ? 'active-personalize' : ''"
                
                v-for="(field, index) in personalizeFields1"
                :key="index"
              >
                <div class="svg mr-2" v-html="field.svg"></div>
                <div class="personalize-title" v-html="field.name"></div>
                <div>
                  <b-form-checkbox
                    :id="field.value + 'id'"
                    :checked="isChecked(field.value)"
                    @change="addRemove(field.value)"
                  ></b-form-checkbox>
                </div>
              </label>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="personalize-modal-title mb-2">Address</div>
            <div class="d-flex flex-column" style="gap: 2px">
              <label
                :for="field.value + 'id'"
                class="d-flex m-0 pl-0 align-items-center personalize-item"
                :class="isChecked(field.value) ? 'active-personalize' : ''"
                
                v-for="(field, index) in personalizeFields2"
                :key="index"
              >
                <div class="svg" v-html="field.svg"></div>
                <div class="personalize-title" v-html="field.name"></div>
                <div>
                  <b-form-checkbox
                    :id="field.value + 'id'"
                    :checked="isChecked(field.value)"
                    @change="addRemove(field.value)"
                  ></b-form-checkbox>
                </div>
              </label>
            </div>
          </div>
          <div class="col-12 col-md-4">
            <div class="personalize-modal-title mb-2">Question’s Answer</div>
            <div class="d-flex flex-column" style="gap: 2px">
              <label
                :for="field.personalization + 'id'"
                class="d-flex m-0 pl-0 align-items-center personalize-item"
                :class="
                  isChecked(field.personalization) ? 'active-personalize' : ''
                "
                
                v-for="(field, index) in questionList"
                :key="field.personalization + index"
              >
                <div class="svg" v-html="field.svg"></div>
                <div
                  class="personalize-title"
                  v-html="formatString( field.personalization)"
                ></div>
                <div>
                  <b-form-checkbox
                    :id="field.personalization + 'id'"
                    :checked="isChecked(`%${field.personalization}%`)"
                    @change="addRemove(`%${field.personalization}%`)"
                  ></b-form-checkbox>
                </div>
              </label>
            </div>
          </div>
        </div>
        <div class="personalize-modal-title my-2" v-if="inputs.length >0">Custom Inputs</div>
        <div class="row"  v-if="inputs.length >0">
          <div
            class="col-12 col-md-4"
            style="margin-bottom: 8px"
            v-for="(field, index) in inputs"
            :key="field.field_name + index"
          >
            <label
              :for="field.field_name + 'id'"
              class="d-flex m-0 pl-0 align-items-center personalize-item"
              :class="isChecked(field.field_name) ? 'active-personalize' : ''"
            >
              <div class="svg" v-html="field.svg"></div>
              <div class="personalize-title" v-html="field.field_name"></div>
              <div>
                <b-form-checkbox
                  :id="field.field_name + 'id'"
                  :checked="isChecked(`%${field.field_name}%`)"
                  @change="addRemove(`%${field.field_name}%`)"
                ></b-form-checkbox>
              </div>
            </label>
          </div>
        </div>
        
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "../../../../Dashboard/Layout/Modal.vue";

export default {
  components: {
    Modal,
  },
  props: {
    value: String,
    questionList: Array,
    inputs: Array,
  },
  data() {
    return {
      inputText: this.value,
      formattedText: "",
      personalizeFields1: [
        {
          name: "Name",
          value: "%name%",
          svg: `<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="1" width="23" height="23" rx="5.5" fill="#EAEBED"/>
<rect x="0.5" y="1" width="23" height="23" rx="5.5" stroke="#DADADC"/>
<path d="M8 15.8333C8 14.5 10.6667 13.7667 12 13.7667C13.3333 13.7667 16 14.5 16 15.8333V16.5H8M14 10.5C14 11.0304 13.7893 11.5391 13.4142 11.9142C13.0391 12.2893 12.5304 12.5 12 12.5C11.4696 12.5 10.9609 12.2893 10.5858 11.9142C10.2107 11.5391 10 11.0304 10 10.5C10 9.96957 10.2107 9.46086 10.5858 9.08579C10.9609 8.71071 11.4696 8.5 12 8.5C12.5304 8.5 13.0391 8.71071 13.4142 9.08579C13.7893 9.46086 14 9.96957 14 10.5ZM6 7.83333V17.1667C6 17.5203 6.14048 17.8594 6.39052 18.1095C6.64057 18.3595 6.97971 18.5 7.33333 18.5H16.6667C17.0203 18.5 17.3594 18.3595 17.6095 18.1095C17.8595 17.8594 18 17.5203 18 17.1667V7.83333C18 7.47971 17.8595 7.14057 17.6095 6.89052C17.3594 6.64048 17.0203 6.5 16.6667 6.5H7.33333C6.59333 6.5 6 7.1 6 7.83333Z" fill="#292929"/>
</svg>
`,
        },
        {
          name: "Email",
          value: "%email%",
          svg: `<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="1" width="23" height="23" rx="5.5" fill="#EAEBED"/>
<rect x="0.5" y="1" width="23" height="23" rx="5.5" stroke="#DADADC"/>
<path d="M17.3333 9.83335L11.9999 13.1667L6.66659 9.83335V8.50002L11.9999 11.8334L17.3333 8.50002M17.3333 7.16669H6.66659C5.92659 7.16669 5.33325 7.76002 5.33325 8.50002V16.5C5.33325 16.8536 5.47373 17.1928 5.72378 17.4428C5.97382 17.6929 6.31296 17.8334 6.66659 17.8334H17.3333C17.6869 17.8334 18.026 17.6929 18.2761 17.4428C18.5261 17.1928 18.6666 16.8536 18.6666 16.5V8.50002C18.6666 7.76002 18.0666 7.16669 17.3333 7.16669Z" fill="#18191C"/>
</svg>
`,
        },
        {
          name: "Phone",
          value: "%phone%",
          svg: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="23" height="23" rx="5.5" fill="#EAEBED"/>
<rect x="0.5" y="0.5" width="23" height="23" rx="5.5" stroke="#DADADC"/>
<path d="M8.41333 11.1933C9.37333 13.08 10.92 14.6267 12.8067 15.5867L14.2733 14.12C14.46 13.9333 14.72 13.88 14.9533 13.9533C15.7 14.2 16.5 14.3333 17.3333 14.3333C17.5101 14.3333 17.6797 14.4036 17.8047 14.5286C17.9298 14.6536 18 14.8232 18 15V17.3333C18 17.5101 17.9298 17.6797 17.8047 17.8047C17.6797 17.9298 17.5101 18 17.3333 18C14.3275 18 11.4449 16.806 9.31946 14.6805C7.19404 12.5551 6 9.67245 6 6.66667C6 6.48986 6.07024 6.32029 6.19526 6.19526C6.32029 6.07024 6.48986 6 6.66667 6H9C9.17681 6 9.34638 6.07024 9.4714 6.19526C9.59643 6.32029 9.66667 6.48986 9.66667 6.66667C9.66667 7.5 9.8 8.3 10.0467 9.04667C10.12 9.28 10.0667 9.54 9.88 9.72667L8.41333 11.1933Z" fill="#292929"/>
</svg>
`,
        },
        {
          name: "Date",
          value: "%date%",
          svg: `<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="1" width="23" height="23" rx="5.5" fill="#EAEBED"/>
<rect x="0.5" y="1" width="23" height="23" rx="5.5" stroke="#DADADC"/>
<path d="M16.6667 17.1667H7.33333V9.83335H16.6667M14.6667 5.16669V6.50002H9.33333V5.16669H8V6.50002H7.33333C6.59333 6.50002 6 7.09335 6 7.83335V17.1667C6 17.5203 6.14048 17.8594 6.39052 18.1095C6.64057 18.3595 6.97971 18.5 7.33333 18.5H16.6667C17.0203 18.5 17.3594 18.3595 17.6095 18.1095C17.8595 17.8594 18 17.5203 18 17.1667V7.83335C18 7.09335 17.4 6.50002 16.6667 6.50002H16V5.16669" fill="#292929"/>
</svg>
`,
        },
        {
          name: "Website",
          value: "%website%",
          svg: `<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="1" width="23" height="23" rx="5.5" fill="#EAEBED"/>
<rect x="0.5" y="1" width="23" height="23" rx="5.5" stroke="#DADADC"/>
<path d="M14.9066 13.8333C14.9599 13.3933 14.9999 12.9533 14.9999 12.5C14.9999 12.0466 14.9599 11.6066 14.9066 11.1666H17.1599C17.2666 11.5933 17.3333 12.04 17.3333 12.5C17.3333 12.96 17.2666 13.4066 17.1599 13.8333M13.7266 17.54C14.1266 16.8 14.4333 16 14.6466 15.1666H16.6133C15.9733 16.2666 14.9533 17.12 13.7266 17.54ZM13.5599 13.8333H10.4399C10.3733 13.3933 10.3333 12.9533 10.3333 12.5C10.3333 12.0466 10.3733 11.6 10.4399 11.1666H13.5599C13.6199 11.6 13.6666 12.0466 13.6666 12.5C13.6666 12.9533 13.6199 13.3933 13.5599 13.8333ZM11.9999 17.8066C11.4466 17.0066 10.9999 16.12 10.7266 15.1666H13.2733C12.9999 16.12 12.5533 17.0066 11.9999 17.8066ZM9.33325 9.83331H7.38658C8.01992 8.72665 9.04658 7.87331 10.2666 7.45998C9.86658 8.19998 9.56659 8.99998 9.33325 9.83331ZM7.38658 15.1666H9.33325C9.56659 16 9.86658 16.8 10.2666 17.54C9.04658 17.12 8.01992 16.2666 7.38658 15.1666ZM6.83992 13.8333C6.73325 13.4066 6.66659 12.96 6.66659 12.5C6.66659 12.04 6.73325 11.5933 6.83992 11.1666H9.09325C9.03992 11.6066 8.99992 12.0466 8.99992 12.5C8.99992 12.9533 9.03992 13.3933 9.09325 13.8333M11.9999 7.18665C12.5533 7.98665 12.9999 8.87998 13.2733 9.83331H10.7266C10.9999 8.87998 11.4466 7.98665 11.9999 7.18665ZM16.6133 9.83331H14.6466C14.4333 8.99998 14.1266 8.19998 13.7266 7.45998C14.9533 7.87998 15.9733 8.72665 16.6133 9.83331ZM11.9999 5.83331C8.31325 5.83331 5.33325 8.83331 5.33325 12.5C5.33325 14.2681 6.03563 15.9638 7.28587 17.214C7.90493 17.8331 8.63986 18.3241 9.4487 18.6592C10.2575 18.9942 11.1244 19.1666 11.9999 19.1666C13.768 19.1666 15.4637 18.4643 16.714 17.214C17.9642 15.9638 18.6666 14.2681 18.6666 12.5C18.6666 11.6245 18.4941 10.7576 18.1591 9.94876C17.8241 9.13992 17.333 8.40499 16.714 7.78593C16.0949 7.16688 15.36 6.67581 14.5511 6.34078C13.7423 6.00575 12.8754 5.83331 11.9999 5.83331Z" fill="#292929"/>
</svg>
`,
        },
        {
          name: "Organisation",
          value: "%organisation%",
          svg: `<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="1" width="23" height="23" rx="5.5" fill="#EAEBED"/>
<rect x="0.5" y="1" width="23" height="23" rx="5.5" stroke="#DADADC"/>
<path d="M11.6666 5.16669L5.33325 8.50002V9.83335H17.9999V8.50002M14.6666 11.1667V15.8334H16.6666V11.1667M5.33325 19.1667H17.9999V17.1667H5.33325M10.6666 11.1667V15.8334H12.6666V11.1667M6.66659 11.1667V15.8334H8.66659V11.1667H6.66659Z" fill="#292929"/>
</svg>
`,
        },
        {
          name: "Score",
          value: "%score%",
          svg: `<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="1" width="23" height="23" rx="5.5" fill="#EAEBED"/>
<rect x="0.5" y="1" width="23" height="23" rx="5.5" stroke="#DADADC"/>
<path d="M11.6666 5.16669L5.33325 8.50002V9.83335H17.9999V8.50002M14.6666 11.1667V15.8334H16.6666V11.1667M5.33325 19.1667H17.9999V17.1667H5.33325M10.6666 11.1667V15.8334H12.6666V11.1667M6.66659 11.1667V15.8334H8.66659V11.1667H6.66659Z" fill="#292929"/>
</svg>
`,
        },
      ],
      personalizeFields2: [
        { name: "Address Line 1", value: "%address_line_1%" },
        { name: "Address Line 2", value: "%address_line_2%" },
        { name: "City", value: "%city%" },
        { name: "State", value: "%state%" },
        { name: "Zip Code", value: "%zip_code%" },
        { name: "Country", value: "%country%" },
      ],
    };
  },
  watch: {
    inputText: {
      handler(newValue) {
        this.formatText(newValue);
        this.$emit("input", newValue);
      },
      immediate: true,
    },
    value(newValue) {
      this.inputText = newValue;
    },
  },

  methods: {
    formatString(input) {
  // Match and extract the question number (the part after 'QA-' and before the last '-')
  const match = input.match(/^QA-(\d+)-\d+$/);

  if (match) {
    const questionNumber = match[1];
    return `Question ${questionNumber} Response`;
  } else {
    return input;
  }
},
    addRemove(value) {
      if (this.isChecked(value)) {
        this.inputText = this.inputText.replaceAll(value, "");
      } else {
        this.addProperty(value);
      }
      this.$refs['personalize-modal'].close();
    },
    isChecked(property) {
      if (this.inputText.toLowerCase().includes(property.toLowerCase())) {
        return true;
      }
      return false;
    },
    addProperty(property) {
      const textInput = this.$refs.textarea;
      const startPos = textInput.selectionStart;
      const endPos = textInput.selectionEnd;
      const textBefore = this.inputText.substring(0, startPos);
      const textAfter = this.inputText.substring(endPos, this.inputText.length);
      this.inputText = textBefore + " " + property + " " + textAfter;
    },
    formatText(inputText) {
      this.formattedText = inputText
        .replaceAll(/\n/g, "<br>")
        .replace(/(\S+)/g, (match) => {
          return `<span>${match}</span>`;
        })
        .replaceAll(/ /g, "&nbsp;")
        .replaceAll(/%([a-zA-Z0-9_-]+)%/g, (match, p1) => {
          return `<span class="rich-text-area-highlight">${p1}</span>`;
        });
    },
    syncScroll() {
      // Sync the scroll position of the div with the textarea
      this.$refs.lineContainer.scrollTop = this.$refs.textarea.scrollTop;
    },
  },
};
</script>

<style scoped>
.personalize-modal-title {
  color: #9ea0a8;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}
.output {
  position: absolute;
  width: 100%;
  height: 100px;
  overflow: scroll;
  top: 0;
  z-index: 1;
  overflow-wrap: anywhere;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
}

.rich-textarea {
  padding: 0px;
  display: block;
  position: relative;
  width: 100%;
  height: 100px;
  background: transparent;
  color: transparent;
  z-index: 11111111;
  caret-color: red;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
  outline: none;
  border: none;
  resize: none;
}
.personalize-item {
  padding: 6px;
  cursor: pointer;
  border-radius: var(--radius-md, 8px);
  transition: 0.3s ease all;
}
.personalize-item:hover {
  background: #f7f7f7d2;
}
.active-personalize {
  background: #f7f7f7;
}
.personalize-title {
  flex: 1;
  color: #73738d;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
<style>
.rich-text-area-highlight {
  padding: var(--spacing-xxs, 2px) var(--spacing-md, 11px);
  border-radius: 4px;
  background: #f7f7f7;
  white-space: nowrap;
}
.rich-text-area-section:focus-within {
  border: 1px solid transparent;
  box-shadow: 0px 0px 1px 1px var(--secondary-color);
}
.rich-text-area-section {
  border-radius: var(--radius-sm, 6px);
  border: 1px solid #d2d8e0;
  background: #fff;
  display: flex;
  padding: var(--spacing-sm, 6px) var(--spacing-lg, 12px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
}
.rich-text-area-button {
  padding: 0;
  border: 0;
  outline: 0;
  color: var(--secondary-color);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 19.6px */
  background: transparent;
}
.output span {
  white-space: nowrap;
}
</style>
